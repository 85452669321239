import React from "react"
import { Link } from "gatsby"
import "./header.scss"
import SideBarMenu from "@components/sidebar/sidebar"
import Sticky from "react-sticky-el"

import Logo from "@images/logo.svg"

const Header = () => (
  <header>
    <Sticky
      className="sticky-wrapper"
      stickyClassName="is-sticky"
      stickyStyle={{ transform: "unset", zIndex: "10" }}
    >
      <div className="header">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/">
              <img className="header-logo" src={Logo} alt="RollSocial" />
            </Link>
            <div>
              <ul className="header-menu d-none d-lg-inline-flex">
                <li>
                  <Link to="/" className="menu-link">
                    Process
                  </Link>
                </li>
                <li>
                  <Link to="/" className="menu-link">
                    Pricing
                  </Link>
                </li>
                <li className="has-submenu">
                  <span className="menu-link">Resources</span>
                  <div className="submenu-wrapper">
                    <ul className="submenu">
                      <li>
                        <Link to="/" className="menu-sublink">
                          The F.I.R.E. Formula
                        </Link>
                      </li>
                      <li>
                        <Link to="/" className="menu-sublink">
                          Social Strategy Webinar
                        </Link>
                      </li>
                      <li>
                        <Link to="/" className="menu-sublink">
                          FAQs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="has-submenu">
                  <span className="menu-link">Contact Us</span>
                  <div className="submenu-wrapper">
                    <ul className="submenu">
                      <li>
                        <a
                          className="menu-sublink"
                          href="mailto:Info@rollsocial.co?subject=RollSocial Question"
                        >
                          Email: Info@rollsocial.co
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <Link to="/" className="btn btn-primary header-cta">
                Free Training
              </Link>
              <div className="d-inline-block d-lg-none">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
