import React from "react"
import { Link } from "gatsby"
import "./footer.scss"

const footer = props => {
  return (
    <div className="footer">
      <svg
        className="wave"
        width="100%"
        height="180px"
        viewBox="0 0 1440 180"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 180L60 175.135C120 170.27 240 159.846 360 134.826C480 109.807 600 70.1931 720 70.1931C840 70.1931 960 109.807 1080 115.367C1200 120.232 1320 90.3475 1380 75.0579L1440 59.7683V3.63588e-06H1380C1320 3.63588e-06 1200 3.63588e-06 1080 3.63588e-06C960 3.63588e-06 840 3.63588e-06 720 3.63588e-06C600 3.63588e-06 480 3.63588e-06 360 3.63588e-06C240 3.63588e-06 120 3.63588e-06 60 3.63588e-06H0V180Z"
          fill="white"
        />
      </svg>

      <div className="w-logo-bg w-logo-bg--opacity"></div>
      <div className="container">
        <div className="row text-md-left justify-content-between align-items-center">
          <div className="col-md-3 col-lg-2 text-center text-md-left">
            <ul className="list-unstyled">
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/">Terms of Service</Link>
              </li>
              <li>
                <Link to="/">Service Agreement</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-lg-2 text-center text-md-left">
            <ul className="list-unstyled">
              <li>
                <Link to="/">FAQs</Link>
              </li>
              <li>
                <Link to="/">Our Method</Link>
              </li>
              <li>
                <Link to="/">Account Support</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-5 align-self-center text-center mt-5 mt-md-0">
            <img
              alt="RollSocial"
              className="d-block mx-auto img-fluid"
              src={require("@images/logo-white.svg")}
              width="300px"
            />
            <span className="d-block d-md-inline-block small pt-3">
              ©2020 Venture Tactics. All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default footer
