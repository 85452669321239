import React from "react"
import { Link } from "gatsby"
import { Accordion, Card } from "react-bootstrap"
import { Nav } from "react-bootstrap"
import "./menu.scss"

import LogoWhite from "@images/logo-white.svg"

export default ({ toggle }) => (
	<div className="navigation">
		<button id="close-sidebar" onClick={toggle}>
			<i class="fa fa-close"></i>
		</button>
		<Link to="/">
			<img className="sidebar-logo" src={LogoWhite} alt="Lead Roll" />
		</Link>

		<Nav className="flex-column">
			<Accordion>
				<Nav.Item>
					<Link to="/">Process</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/">Pricing</Link>
				</Nav.Item>
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="resources">
						Resources
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="resources">
						<Card.Body>
							<Nav.Item>
								<Link to="/">The F.I.R.E. Formula</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="/">Social Strategy Webinar</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="/">FAQs</Link>
							</Nav.Item>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey="contact">
						Contact Us
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="contact">
						<Card.Body>
							<Nav.Item>
								<a href="mailto:Info@rollsocial.co?subject=RollSocial Question">
									Email: Info@rollsocial.co
								</a>
							</Nav.Item>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		</Nav>

		<Link to="/" className="btn btn-primary sidebar-cta m-0 shadow-dark">
			Free Training
		</Link>
	</div>
)
